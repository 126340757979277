export var ConfigJson={

    "env":[
        
        {
            "displayName":"",
            "accountId":"",
            "gateway":""
        },
        /*
        {
            "displayName":"ExperiencePOC01.avaya.com",
            "accountId":"GPWNAL",
            "gateway":"us.cc"
        },
        {
            "displayName":"ExperiencePOC02.avaya.com",
            "accountId":"WXQXRC",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC03.avaya.com",
            "accountId":"KMTIFJ",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC04.avaya.com",
            "accountId":"KYWXSL",
            "gateway":"sa.cc"
        },
        {
            "displayName":"ExperiencePOC05.avaya.com",
            "accountId": "BAJQKT",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC06.avaya.com",
            "accountId": "MPEFRJ",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC07.avaya.com",
            "accountId": "JKLNAM",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC08.avaya.com",
            "accountId": "DJCUEJ",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC09.avaya.com",
            "accountId": "YOQDEZ",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC10.avaya.com",
            "accountId": "PTUSPQ",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC11.avaya.com",
            "accountId": "NJTOJP",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC12.avaya.com",
            "accountId": "GGEWDN",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC13.avaya.com",
            "accountId": "GYLEME",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC14.avaya.com",
            "accountId": "JSHMHX",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC15.avaya.com",
            "accountId": "UBAUTN",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC16.avaya.com",
            "accountId": "LUMEAD",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC17.avaya.com",
            "accountId": "KJYGJB",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC18.avaya.com",
            "accountId": "ECSODY",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC19.avaya.com",
            "accountId": "VDYGGL",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC20.avaya.com",
            "accountId": "WNKZQU",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC21.avaya.com",
            "accountId": "IWMCEG",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC22.avaya.com",
            "accountId": "MTMJPM",
            "gateway":"eu.cc"
        },
         {
            "displayName":"ExperiencePOC23.avaya.com",
            "accountId": "COWTFH",
            "gateway":"na.cc"
        },
         {
            "displayName":"ExperiencePOC24.avaya.com",
            "accountId": "GGTMJE",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC27.avaya.com",
            "accountId": "PRTQKU",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC28.avaya.com",
            "accountId": "PCAXBK",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC29.avaya.com",
            "accountId": "BKMLWV",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC30.avaya.com",
            "accountId": "TWDMLS",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC31.avaya.com",
            "accountId": "XPECJQ",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC32.avaya.com",
            "accountId": "AQURTN",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC33.avaya.com",
            "accountId": "UDQKML",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC34.avaya.com",
            "accountId": "KRKNQY",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC35.avaya.com",
            "accountId": "TACNOB",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC36.avaya.com",
            "accountId": "QERMEA",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC37.avaya.com",
            "accountId": "MLNLEZ",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC38.avaya.com",
            "accountId": "SYBZWE",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC39.avaya.com",
            "accountId": "ZTMILS",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC40.avaya.com",
            "accountId": "KJLSUV",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC41.avaya.com",
            "accountId": "MORVPS",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC42.avaya.com",
            "accountId": "PYQRIK",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC43.avaya.com",
            "accountId": "CJOCKR",
            "gateway":"na.cc"
        },
        
        {
            "displayName":"ExperiencePOC45.avaya.com",
            "accountId": "ZOZGBL",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC47.avaya.com",
            "accountId": "HIFLTD",
            "gateway":"na.cc"
        },
        
        {
            "displayName":"ExperiencePOC46.avaya.com",
            "accountId": "SBZXOY",
            "gateway":"eu.cc"
        },
        
        {
            "displayName":"ExperiencePOC48.avaya.com",
            "accountId": "XXOMYA",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC44.avaya.com",
            "accountId": "MFGABY",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC49.avaya.com",
            "accountId": "MLTLHP",
            "gateway":"eu.cc"
        },
        
        {
            "displayName":"ExperiencePOC50.avaya.com",
            "accountId": "LIRBDA",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC51.avaya.com",
            "accountId": "ACZNGR",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC52.avaya.com",
            "accountId": "BTMVGD",
            "gateway":"na.cc"
        },
        
        {
            "displayName":"ExperiencePOC54.avaya.com",
            "accountId": "EEZHAV",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC55.avaya.com",
            "accountId": "YGRQUI",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC57.avaya.com",
            "accountId": "FAGDQV",
            "gateway":"eu.cc"
        },
        {
            "displayName":"ExperiencePOC56.avaya.com",
            "accountId": "JPZRQP",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC53.avaya.com",
            "accountId": "FOHWHZ",
            "gateway":"eu.cc"
        },
        
        {
            "displayName":"ExperiencePOC58.avaya.com",
            "accountId": "OWIVNF",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC59.avaya.com",
            "accountId": "ZZLYXO",
            "gateway":"na.cc"
        }, 
        
        {
            "displayName":"ExperiencePOC62.avaya.com",
            "accountId": "RWKTWX",
            "gateway":"na.cc"
        },
        
        {
            "displayName":"ExperiencePOC64.avaya.com",
            "accountId": "WIUEIZ",
            "gateway":"na.cc"
        },
        {
            "displayName":"ExperiencePOC61.avaya.com",
            "accountId": "EYRNXL",
            "gateway":"na.cc"
        },
        
        {
            "displayName":"ExperiencePOC63.avaya.com",
            "accountId": "IIBXJV",
            "gateway":"eu.cc"
        },
        /*
        {
            "displayName":"ExperiencePOC66.avaya.com",
            "accountId": "AWUXTF",
            "gateway":"uk.cc"
        },
        {
            "displayName":"ExperiencePOC67.avaya.com",
            "accountId": "FCXHTM",
            "gateway":"uk.cc"
        },
        {
            "displayName":"ExperiencePOC68.avaya.com",
            "accountId": "XCOIZE",
            "gateway":"uk.cc"
        },
        {
            "displayName":"ExperiencePOC70.avaya.com",
            "accountId": "UVODHS",
            "gateway":"uk.cc"
        },
        
        {
            "displayName":"ExperiencePOC71.avaya.com",
            "accountId": "YZWFBG",
            "gateway":"uk.cc"
        },
        {
            "displayName":"ExperiencePOC73.avaya.com",
            "accountId": "CYNCBQ",
            "gateway":"uk.cc"
        },
        
        */
        {
            "displayName":"ExperiencePOC68.avaya.com",
            "accountId": "XCOIZE",
            "gateway":"uk.cc"
        },
        {
            "displayName":"ExperiencePOC72.avaya.com",
            "accountId": "KKBKKP",
            "gateway":"uk.cc"
        },
        
        {
            "displayName":"ExperiencePOC74.avaya.com",
            "accountId": "WAFBHW",
            "gateway":"uk.cc"
        },
        {
            "displayName":"ExperiencePOC75.avaya.com",
            "accountId": "USZFMN",
            "gateway":"uk.cc"
        },
        {
            "displayName":"ExperiencePOC76.avaya.com",
            "accountId": "RDWSZL",
            "gateway":"uk.cc"
        }
    ],
    "lang":[
        "Chinese",
        "English",
        "French",
        "German",
        "Hindi",
        "Japanese",
        "Russian",
        "Spanish"
    ]

}
