import { Component, OnInit, AfterViewInit } from "@angular/core";
import { DigitalService } from "../digital.service";
import { Message } from "../Message";
import { MessageResponse } from "../MessageResponse";
import { Body } from "../Message";
import { ElementText } from "../Message";
import { startWith, switchMap } from "rxjs/operators";
import { interval, Subscription, EMPTY } from "rxjs";
import { Globals } from "../globals";
import { EngagementNotification } from "../EngagementNotification";
import { DialogComponent } from "../dialog/dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { TokenService } from "../services/token.service";
import * as moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogErrorComponent } from "../dialog-error/dialog-error.component";
import MessagesPage from "../types/Message";
declare function initializeRichText(): any;
declare function formatOutgoingMessage(): any;
declare function toggleTUIToolbarFromJS(): any;
declare function clearMessage(): any;
declare function convertToHTML(string): any;

// const linkRegx = /(\(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}\))/g
const linkRegx =
  /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.css"],
})
export class ChatComponent implements OnInit, AfterViewInit {

  public latestMsg: string = "";
  public allMsgInOne = [];
  messagesErrMaxRetries: number = 0;
  notificationErrMaxRetries: number = 0;
  counter = 0;
  public senderName = "";
  public senderId = this.digital.getCustomerIdentifier();
  messagesSubscription: any;
  notificationSubscription: any;
  public msgsflag = false;
  public messageFormat: string = "PLAINTEXT";
  dateTime: Date = new Date();
  status = "";
  isAgentConnected=false;
  isMinimzed = false;
  msgNative = "";
  currentPage: number = 1;
  basePath = window.location.pathname;

  constructor(
    public digital: DigitalService,
    public dialog: MatDialog,
    private tokenService: TokenService,
    private _snackBar: MatSnackBar
  ) {
    if (
      this.basePath === "undefined" ||
      this.basePath === "" ||
      this.basePath === "/"
    ) {
      this.basePath = "/v1beta/";
    }
  }
  ngOnInit() {
    this.subscribeToMessages();
    this.subscribeToNotifications();
    // console.log('Chat initialized');
    // console.log('senderName = ' + this.senderName);
    this.getSenderName();
  }

  ngAfterViewInit() {
    // initializeRichText();
  }

   linkify(inputText) {
    var replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 =
      /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(
      replacePattern1,
      '<a href="$1" target="_blank">$1</a>'
    );

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(
      replacePattern2,
      '$1<a href="http://$2" target="_blank">$2</a>'
    );

    //Change email addresses to mailto:: links.
    replacePattern3 =
      /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(
      replacePattern3,
      '<a href="mailto:$1">$1</a>'
    );

    return replacedText;
}
  public terminateEngagement() {
    if (this.digital.getStatus() == "Chat End") {
      this.digital.setEngagement(null);
      this.digital.setChatStatus(false);
      return;
    }
    this.digital.terminateEngagement().subscribe((result) => {
      console.log("Enagement terminated");
        this.digital.setChatStatus(false);
        this.digital.setEngagement(null);
      });
  }

  public getStatus(): string {
    return this.digital.getStatus();
  }

  
  public minimize(): void {
    this.isMinimzed=!this.isMinimzed;
  }

  checkContainsLink(str){
    return linkRegx.test(str);
  }

  public getSenderName(): string {
   
   return this.digital.getCustomerSenderName();
  }

  sendMessage() {

    if (this.msgNative != null && this.msgNative.trim() != "") {
      let elementText = new ElementText(
        this.msgNative.trim(),
        this.messageFormat
      );
      let body = new Body("text", elementText, "payload");
      let message = new Message(
        this.digital.getSessionId(),
        this.digital.getDialogId(),
        "correlationId",
        this.digital.getEngagementId(),
        this.digital.getCustomerIdentifier(),
        this.digital.getCustomerIdentifier(),
        "CUSTOMER",
        "parentMessageId",
        body,
        "providerParentMessageId",
        "CHAT",
        "channelProviderId"
      );

      this.digital.sendMessage(message).subscribe(
        (result) => {
        this.printGetMessage(result);
        },
        (error) => {
          this.dialog.open(DialogErrorComponent, {
            data: {
              title: "error",
              text: "Failed Sending Message",
            },
            height: "fit-content",
            width: "350px",
      });
        }
      );

    this.msgNative = "";
  } 
  }


  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");
    this.msgNative = `${this.msgNative}${pastedText}`;
  }

  public smrtOnCtrlEnter(e): void {
  if (e.keyCode === 13 && !e.shiftKey) {
      this.sendMessage();
      e.preventDefault();
    return;
  }
    if(e.keyCode === 13  && e.shiftKey){
      e.preventDefault();
      this.msgNative = `${this.msgNative} \n`;
      return;
    }

  }

  
  public toggleTUIToolbar(): void {
	// console.log("initiating toggle of editor toolbar");
	toggleTUIToolbarFromJS();
  }

  public printGetMessage(message): void {
    // console.log("Message received : " + JSON.stringify(message) + " :: MessageBodyText : " + message.body.elementText.text);
  }

  chatEnded() {
    return this.digital.getStatus() == "Chat End";
  }
    
  getMessages() {
    this.digital.getMessage().then(
      (results: MessageResponse[]) => {

    if (this.digital.getChatStatus) {
		  if (results != null) {
            results.forEach((result) => {
				if (results[this.counter] != null) {
                if (
                  "MARKDOWN" ===
                  results[
                    this.counter
                  ].body.elementText.textFormat.toUpperCase()
                ) {
                  results[this.counter].body.elementText.text = convertToHTML(
                    results[this.counter].body.elementText.text
                  );
				  }

                this.dateTime.setTime(
                  results[this.counter].receivedTimestamp * 1000
                );
                var messageTitle = `<em> <strong> ${
                  results[this.counter].senderName
                } </strong> |  ${moment(this.dateTime).format(
                  "M/D/YY h:mm A"
                )} </em>`;
				  results[this.counter].senderName = messageTitle;

				  this.allMsgInOne.push(results[this.counter]);
				  this.counter = this.counter + 1;
              }
            });
          }
        }
      },
      (error) => {
        if (this.messagesErrMaxRetries >= 3) {
          this.dialog.open(DialogErrorComponent, {
            data: {
              title: "error fetching messages",
              text: error.error
                ? error.error.detail
                : "Internal Server Error While Getting Messages",
            },
            height: "fit-content",
            width: "350px",
          });
          // terminate chat
          this.digital.setStatus("Chat End");
          this.setStatus("Chat Terminated");
          // terminate polling
          this.onEngagmentTerminated();
          this.messagesErrMaxRetries = 0;
        } else {
          console.log("retrying");
          this.messagesErrMaxRetries++;
				}
      }
    );
			}
  getMessagesV1Beta() {
    this.digital
      .getMessageV1Beta(this.currentPage)
      .then((data: MessagesPage) => {
        console.log(data);
        // first calc if we should increase currentPage value or not
        // check if we should push messages or not to ui
        // second set Messages
        let results = data.messages;
        if (this.digital.getChatStatus || true) {
          // map messages
          let mappedMessages: any = [];
          if (results != null) {
            mappedMessages = results.map((msg) => {
              if (
                "MARKDOWN" === msg.body.elementText.textFormat.toUpperCase()
              ) {
                msg.body.elementText.text = convertToHTML(
                  msg.body.elementText.text
                );
		}

              this.dateTime.setTime(msg.receivedAt);
              var dateString = this.dateTime.toDateString();
              console.log(msg);
              var messageTitle = `<em> <strong> ${
                msg.senderParticipantName
              } </strong> ${
                msg.senderParticipantName.length ? "|" : ""
              }  ${moment(msg.receivedAt).format("M/D/YY h:mm A")} </em>`;
              // var messageTitle = "<em> <strong>" + msg.senderParticipantName + "</strong>" + " | "
              // + ((dateString === new Date().toDateString()) ? "" : (this.dateTime.toLocaleDateString() + ", ")) + this.dateTime.toLocaleTimeString() + "</em>";

              msg.senderParticipantName = messageTitle;
              return msg;
            });
            let newMessages = [];
            newMessages = mappedMessages.slice(
              this.allMsgInOne.length -
                (this.currentPage - 1) * data.pagination.pageSize
            );
            // push new messages to the thread
            this.allMsgInOne = [...this.allMsgInOne, ...newMessages];
            console.log(this.allMsgInOne);
            // increase total number by one
            if (data.pagination.total > this.allMsgInOne.length) {
              ++this.currentPage;
            }
          }
        }
      });
  }
  
  subscribeToMessages(): void {
    if (this.digital.getChatStatus) this.msgsflag = true;
    var messageFunction: Function;
    if (this.basePath === "/v1beta/") {
      messageFunction = this.getMessagesV1Beta;
    } else if (this.basePath === "/v1/") {
      messageFunction = this.getMessages;
    } else {
      return;
    }
    messageFunction.call(this);
    this.messagesSubscription = setInterval(() => {
      messageFunction.call(this);
    }, 1000);
  }
  public setStatus(status: string): void {
    this.status = status;
  }

  subscribeToNotifications(): void {

    this.notificationSubscription = setInterval(() => {
      this.digital.getNotifications().then(
        (results: EngagementNotification[]) => {
          // console.log("notifications response - " + JSON.stringify(results));
          if (results == null) {
            return null;
          }
          // console.log(JSON.stringify(results));
          results.forEach((result) => {
            switch (result.event) {
              case "PARTICIPANT_ADDED": {
                this.digital.updateNofitifactionDate(
                  new Date(result.createTimeMillis)
                );
                if (
                  result.participantType == "CUSTOMER" &&
                  this.isAgentConnected == false
                ) {
                  this.digital.setStatus("Waiting for agent");
                  this.setStatus("Waiting for agent");
                } else if (
                  result.participantType == "CUSTOMER" &&
                  this.isAgentConnected == true
                ) {
                  // console.log("Agent connected flag is true. Not changing the status or color of bulb.");
                } else if (result.participantType == "AGENT") {
                  
                  this.digital.setStatus("Agent Connected");
                  this.setStatus("Agent Connected");
                  this.isAgentConnected = true;
                } else if (result.participantType == "SUPERVISOR") {
                  this.digital.setStatus("Supervisor Connected");
                  this.setStatus("Supervisor Connected");
                  this.isAgentConnected = true;
                  // this.isAgentConnected = false;
                }
                // this.subscribeToMessages();

                break;
              }

              case "MATCH_QUEUED": {
                if (this.isAgentConnected == false){
                  this.digital.updateNofitifactionDate(
                    new Date(result.createTimeMillis)
                  );
                  this.digital.setStatus("Waiting for agent");
                  this.setStatus("Waiting for agent");
                } else if (this.isAgentConnected == true) {
                  // console.log("Agent connected flag is true. Not changing the status or color of bulb.");
                }

                break;
              }
              case "PARTICIPANT_DISCONNECTED": {
               if (result.displayName === this.getSenderName()){
                // window.alert("############################################"+ this.getSenderName() + "#########"+result.displayName);
                  this.digital.updateNofitifactionDate(
                    new Date(result.createTimeMillis)
                  );
                  this.digital.setPayload("av-state-away");
                  this.digital.setStatus("Chat End");
                  this.setStatus("Chat Terminated");
                  this.onEngagmentTerminated();
                } else if (result.participantType == "SUPERVISOR") {
                  this.digital.setStatus("Agent Connected");
                  this.setStatus("Agent Connected");
                  this.isAgentConnected = true;
                } else {

                  this.digital.updateNofitifactionDate(
                    new Date(result.createTimeMillis)
                  );
                  this.digital.setPayload("av-state-away");
                  this.digital.setStatus("Waiting for agent");
                  this.setStatus("Waiting for agent");
               }
                
                
                break;
              }
              case "ENGAGEMENT_TERMINATED": {
                
                this.digital.updateNofitifactionDate(
                  new Date(result.createTimeMillis)
                );
                this.digital.setPayload("av-state-offline");
                this.digital.setStatus("Chat End");
                this.setStatus("Chat Terminated");
                this.onEngagmentTerminated();

                break;
              }
              case "ERROR_NOTIFICATION": {
                if (
                  new Date(result.createTimeMillis) > this.digital.getCreated()
                ) {
                  this.digital.updateNofitifactionDate(
                    new Date(result.createTimeMillis)
                  );
                  this.digital.setStatus("Error");
                  let dialogRef = this.dialog.open(DialogComponent, {
                    data: {
                      name: "error",
                      message: result.errorNotification.errorCode,
                    },
                    height: "200px",
                    width: "300px",
                  });

                }
              }
              }
          });
        },
        (error) => {
          if (this.notificationErrMaxRetries >= 3) {
            this.notificationErrMaxRetries = 0;
            this.dialog.open(DialogErrorComponent, {
              data: {
                title: "error",
                text: error.error
                  ? error.error.detail
                  : "Internal Server Error While Getting Notifications",
              },
              height: "fit-content",
              width: "350px",
            });

            // terminate chat
            this.digital.setStatus("Chat End");
            this.setStatus("Chat Terminated");
            // terminate polling
            this.onEngagmentTerminated();
          } else {
            this.notificationErrMaxRetries++;
            }

          }
          );
    }, 2800);
  }

  onEngagmentTerminated() {
    // console.log('onEngagmentTerminated')
    clearInterval(this.messagesSubscription);
    clearInterval(this.notificationSubscription);
  }

  ngOnDestroy() {
    this.onEngagmentTerminated();
  }

}
